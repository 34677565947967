export const apiConstants = {
    GET_USERS: '/get-added-member',
    USER_SIGNUP: '/signup',
    USER_LOGIN: '/login',
    USER_OTP: '/otp',
    USER_ACCOUNT_ACTIVATION: '/activate-account',
    RESEND_EMAIL: '/resend-email',
    FORGOT_PASSWORD: '/forgot-password',
    CONTACT_US: '/contact-us',
    SUBSCRIBE_NEWSLETTER: '/newsLetter/add-subscriber',
    ADD_MEMBERS: '/add-members',
    CHECK_REFER_LINK: '/check-reference',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_REGISTER_EMAIL: '/verify-register-email',
    VERIFY_OTP_EMAIL: '/verify-otp-email',
    GET_ADMIN_REFERAL: '/get-admin-referal',
    PRE_REGISTER: '/pre-register',
    GET_COUNTRY_CODE: '/get-country',
    UPDATE_PROFILE_DETAILS: '/update-profile',
    GET_PROFILE: '/profile',
    UPLOAD_IMAGE: '/update-profile',
    CHANGE_PASSWORD: '/update-password',
    GET_CURRENT_USERS: '/get-current-user',
    GET_LINK: '/get-referal-link',
    USER_VERIFY: '/user-check',
    SEND_EMAIL_NOTIFICATIONS: '/notifications/user-email-notifications',
    GET_SETTINGS: '/get-user-setting',
    FOUNDER_POSITION: '/get-founder-position',
    GET_REGISTERED_USER: '/user-count',
    GET_NEW_MEMBERS: '/position/get-new-members',
    GET_TRANSACTION: '/wallet/alltransaction',
    ADD_WALLET: '/wallet/create',
    SEND_MONEY: '/wallet/transfer',
    GET_WALLET: '/wallet/get-wallet-address',
    GET_PURCHASED_POSITION: '/position/get-purchased-position',
    // ADD_POSITION : '/position/add-position',
    GET_BALANCE: '/wallet/balance',
    // WITHDRAW_MONEY: '/wallet/transfer',
    WITHDRAW_MONEY: '/wallet/withdraw-request',
    GET_POSITION_VIEW: '/position/get-position-view',
    POSITION_BY_NUMBER: '/position/position-by-number',
    GET_NOMINEE: '/user-nominee/get',
    UPDATE_NOMINEE: '/user-nominee/update',
    CREATE_NOMINEE: '/user-nominee/create',
    GET_USER_TRANSACTION: '/wallet/get-user-transaction',
    GET_WALLET_USER: '/wallet/get-wallet-user',
    SEND_USER_MONEY: '/wallet/send-multiple-user',
    UPDATE_BALANCE: '/wallet/update-wallet-balance',
    GET_RE_ENTRY_POSITION: '/position/get-re-entry-position',
    GET_EARNED_INCOME: '/position/earned-income',
    POSITION_ALL: '/position/position-all',
    RECOVER_WALLET: '/wallet/recover-user-password',
    GET_WITHDRAW_TRANSACTION: '/wallet/get-internal-user-transaction',
    GET_PENDING_POSITION_COUNT: '/general_settings/get-user-pending-position',
    SEARCH_POSITION_NUMBER: '/report/get-position-by-no',
    ADD_TESTIMONIALS: '/testimonial/add-testimonials',
    GET_FAQ: '/faq/get-faq',
    GET_COMPETITION_LIST: '/competition-history/monthly-competition',
    GET_MARKETING_LIST: '/market/get-free-market',
    GET_TESTIMONIALS_LIST: '/testimonial/get-approved-testimonials',
    ADD_SUPPORT_TICKET: '/support/add-support-ticket',
    GET_SUPPORT_TICKET: '/support/get-support-ticket-by-user-id',
    EDIT_SUPPORT_TICKET: '/support/edit-user-support-ticket',
    DELETE_SUPPORT_TICKET: '/support/delete-support-ticket',
    COMMENT_ON_SUPPORT_TICKET: '/support/comment-support-ticket',
    GET_SUPPORT_TICKET_BY_ID: '/support/get-support-ticket-by-id',
    GET_MENU: '/menu/get-menu',
    GET_MENU_BY_ID: '/menu/get-menu-by-id',
    WALLET_OTP: '/wallet-otp',
    UPDATE_WALLET_KEY: '/wallet/update-wallet-key',
    EXTERNAL_WALLET_KEY: '/wallet/external-wallet-add',
    GET_PLAN: '/plan/get-plan',
    PLAN_ADD_POSITION: '/plan-position/plan-add-position',
    RE_ENTRY_PLAN_POSITION: '/plan-position/plan-get-re-entry-position',
    PURCHASE_POSITION_PLAN_POSITION: '/plan-position/plan-get-purchased-position',
    GET_POSITION_PLAN_VIEW: '/plan-position/plan-get-position-view',
    PLAN_POSITION_BY_NUMBER: '/plan-position/plan-position-by-number',
    PLAN_SEARCH_POSITION_NUMBER: '/plan-position/plan-get-position-by-no',
    PLAN_POSITION_ALL: '/plan-position/plan-position-all',

    // notification constants
    GET_USER_NOTIFICATION: '/push-notifications/get-users-notifications',
    READ_USER_NOTIFICATION: '/push-notifications/read-notifications',
    HIDE_USER_NOTIFICATION: '/push-notifications/hide-notifications',
    GET_USER_NOTIFICATION_BY_ID: '/push-notifications/get-notification-by-id',
    UNREAD_NOTIFICATION: '/push-notifications/unread-notifications',
    ARCHIVE_USER_NOTIFICATION: '/push-notifications/archive-notifications',

    // Global Pool constants
    GET_POOL_USER: '/global-pool/get-pool-user',
    GET_AVAILABLE_PLAN: '/global-pool/get-available-plan',
    ACTIVATE_GLOBAL_USER: '/global-pool/activate-global-user',
    UPDATE_POOl_SETTING: '/global-pool/update-pool-setting',
    GET_POOL_REQUEST: '/global-pool/get-pool-request',
    PURCHASE_FOR_REFERAL_USER: '/global-pool/purchase-for-referal-user',

    // message constants
    GET_SENDER_USER: '/internal-message/get-sender-user',
    SEND_MESSAGE: '/internal-message/send-message',
    GET_CHAT_BY_USER_ID: '/internal-message/get-message',
    REQUEST_POOL_USER: '/internal-message/request-pool-user',
    GET_MESSAGED_USER_LIST: '/internal-message/get-all-mesaged-user-list',


    // Advertisment routes
    ADD_ADVERTISMENT: '/advertisment/add-advertisment',
    GET_ADVERTISMENT: '/advertisment/banner-list',
    GET_CREDIT: '/advertisment/credit-list',
    DELETE_BANNER: '/advertisment/delete-Advertisment',
    BANNER_STATUS: '/advertisment/banner-status',
    GET_RANDOM_ADVERTISMENT: '/advertisment/random-banner-list',
    GET_ADVERTISMENT_CREDIT: '/advertisment/get-advertisment-credit',
    PURCHASE_CREDIT: '/advertisment/purchase-banner-credit',
    GET_AD_SETTING: '/advertisment/get-advertisment-setting',
    EDIT_ADVERTISMENT: '/advertisment/edit-user-advertisment',
    // Advertisment report
    ADD_ADVERTISMENT_REPORT: '/advertisment/add-advertisment-report',
    GET_FOOTER_BANNER: '/advertisment/get-footer-random-banner',
    GET_PROMO_BANNER_LIST: '/advertisment/get-promo-banners',

    // Matrix Plan API's

    MATRIX_ADD_POSITION: '/matrix/matrix-add-position',
    MATRIX_POSITION_ALL: '/matrix/matrix-position-all',
    MATRIX_POSITION_PLAN_POSITION: '/matrix/plan-get-purchased-position',
    MATRIX_RE_ENTRY_PLAN_POSITION: '/matrix/plan-get-re-entry-position',
    MATRIX_POSITION_BY_NUMBER:'/matrix/position-by-number',
    MATRIX_GET_PLAN_POSITION_PLAN_VIEW:'/matrix/get-position-view'
};
