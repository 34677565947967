export const userConstants = {

    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',

    USER_SIGNUP_REQUEST: 'USER_SIGNUP_REQUEST',
    USER_SIGNUP_SUCCESS: 'USER_SIGNUP_SUCCESS',
    USER_SIGNUP_FAILURE: 'USER_SIGNUP_FAILURE',
    USER_SIGNUP_RESTORE_DEFAULT: 'USER_SIGNUP_RESTORE_DEFAULT',

    USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    GET_CURRENT_USER_REQUEST: 'GET_CURRENT_USER_REQUEST',
    GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
    GET_CURRENT_USER_FAILURE: 'GET_CURRENT_USER_FAILURE',

    ACCOUNT_ACTIVE_REQUEST: 'ACCOUNT_ACTIVE_REQUEST',
    ACCOUNT_ACTIVE_SUCCESS: 'ACCOUNT_ACTIVE_SUCCESS',
    ACCOUNT_ACTIVE_ERROR: 'ACCOUNT_ACTIVE_ERROR',

    SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
    SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
    SEND_EMAIL_FAILURE: 'SEND_EMAIL_FAILURE',    
    SEND_EMAIL_RESTORE_DEFAULT: 'SEND_EMAIL_RESTORE_DEFAULT',

    ADD_MEMBERS_REQUEST: 'ADD_MEMBERS_REQUEST',
    ADD_MEMBERS_SUCCESS: 'ADD_MEMBERS_SUCCESS',
    ADD_MEMBERS_FAILURE: 'ADD_MEMBERS_FAILURE',

    CHECK_REFER_LINK_REQUEST: 'CHECK_REFER_LINK_REQUEST',
    CHECK_REFER_LINK_SUCCESS: 'CHECK_REFER_LINK_SUCCESS',
    CHECK_REFER_LINK_FAILURE: 'CHECK_REFER_LINK_FAILURE',

    ADMIN_REFERAL_REQUEST: 'ADMIN_REFERAL_REQUEST',
    ADMIN_REFERAL_SUCCESS: 'ADMIN_REFERAL_SUCCESS',
    ADMIN_REFERAL_FAILURE: 'ADMIN_REFERAL_FAILURE',

    GET_COUNTRY_LIST_REQUEST: 'GET_COUNTRY_LIST_REQUEST',
    GET_COUNTRY_LIST_SUCCESS: 'GET_COUNTRY_LIST_SUCCESS',
    GET_COUNTRY_LIST_FAILURE: 'GET_COUNTRY_LIST_FAILURE',

    UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

    UPLOAD_IMAGE_REQUEST: 'UPLOAD_IMAGE_REQUEST',
    UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
    UPLOAD_IMAGE_FAILURE: 'UPLOAD_IMAGE_FAILURE',

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    GENERATE_LINK_REQUEST: 'GENERATE_LINK_REQUEST',
    GENERATE_LINK_SUCCESS: 'GENERATE_LINK_SUCCESS',
    GENERATE_LINK_FAILURE: 'GENERATE_LINK_FAILURE',

    VERIFY_USER_REQUEST: 'VERIFY_USER_REQUEST',
    VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
    VERIFY_USER_FAILURE: 'VERIFY_USER_FAILURE',
    RESTORE_VERIFY_USER: 'RESTORE_VERIFY_USER',

    PERSONALISED_EMAIL_REQUEST: 'PERSONALISED_EMAIL_REQUEST',
    PERSONALISED_EMAIL_SUCCESS: 'PERSONALISED_EMAIL_SUCCESS',
    PERSONALISED_EMAIL_FAILURE: 'PERSONALISED_EMAIL_FAILURE',

    MAIL_UPDATE_REQUEST: 'MAIL_UPDATE_REQUEST',
    MAIL_UPDATE_SUCCESS: 'MAIL_UPDATE_SUCCESS',
    MAIL_UPDATE_FAILURE: 'MAIL_UPDATE_FAILURE',

    GET_SETTING_REQUEST: 'GET_SETTING_REQUEST',
    GET_SETTING_SUCCESS: 'GET_SETTING_SUCCESS',
    GET_SETTING_FAILURE: 'GET_SETTING_FAILURE',

    GET_FOUNDER_POSITION_REQUEST: 'GET_FOUNDER_POSITION_REQUEST',
    GET_FOUNDER_POSITION_SUCCESS: 'GET_FOUNDER_POSITION_SUCCESS',
    GET_FOUNDER_POSITION_FAILURE: 'GET_FOUNDER_POSITION_FAILURE',

    GET_COUNT_REQUEST: 'GET_COUNT_REQUEST',
    GET_COUNT_SUCCESS: 'GET_COUNT_SUCCESS',
    GET_COUNT_FAILURE: 'GET_COUNT_FAILURE',

    SEND_TOKEN_EMAIL_REQUEST: 'SEND_TOKEN_EMAIL_REQUEST',
    SEND_TOKEN_EMAIL_SUCCESS: 'SEND_TOKEN_EMAIL_SUCCESS',
    SEND_TOKEN_EMAIL_FAILURE: 'SEND_TOKEN_EMAIL_FAILURE',

    VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
    VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
    VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE'
};
