import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, walletAction, genealogyActions, userActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFormik } from "formik";
import * as Yup from "yup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DeletePopup from '../Common/Popup/DeletePopup';
import WalletUpdatePopup from '../Common/Popup/WalletUpdatePopup';
import CreateWalletPopup from '../Common/Popup/CreateWalletPopup';
import RecoverWalletPopup from '../Common/Popup/RecoverWalletPopup';
import CreditPurchasePopup from '../Common/Popup/CreditPurchasePopup';

var speakeasy = require("speakeasy");
var QRCode = require('qrcode.react');

const Wallet = (props) => {

  const dispatch = useDispatch();
  const [alertRes, setAlertRes] = useState('');
  const [siteConstants, setSiteConstants] = useState([]);
  const [showSendMoney, setShowSendMoney] = useState(false);
  const [withdrawPopup, setWithdrawPopup] = useState(false)
  const [showWithdraw, setShowWithdraw] = useState(false)
  const [isCopied, setIsCopied] = useState(false);
  const [walletDetails, setWalletDetails] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [userList, setUserList] = useState([]);
  const [query, setQuery] = useState('');
  const [commonErr, setCommonErr] = useState('');
  const [updatePopup, setUpdatePopup] = useState(false);
  const [sendMoneyLoading, setSendMoneyLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [transferDetails, setTransferDetails] = useState('');
  const [withdrawDetails, setWithdrawDetails] = useState('');
  const [amtErr, setAmtErr] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [otpShow, setOtpShow] = useState(false);
  const [otpFlag, setOtpFlag] = useState(false);
  const [otpRequestFrom, setotpRequestFrom] = useState('');
  const [flagQuery, setFlagQuery] = useState(false);
  const [walletUpdatePopup, setWalletUpdatePopup] = useState(false);
  const [walletCreatePopup, setWalletCreatePopup] = useState(false);
  const [recoverWalletPopup, setRecoverWalletPopup] = useState(false);
  const [creditShow, setCreditShow] = useState(false)

  const [walletIncome, setWalletIncome] = useState({
    'spentIncome': 0,
    'earnedIncome': 0,
    'lostIncome': 0
  })
  const [popupLoading, setPopupLoading] = useState({
    'createLoading': false, 'sendLoading': false, 'withdrawLoading': false, 'updateLoading': false, recoverLoading: false,
    otpLoading: false
  })

  const { alert, language, getWallet, getBalance, getWalletUser, sendTokenEmail, sendMoneyRes, getEarnedIncome } = useSelector(
    state => ({
      alert: state.alert,
      language: state.language,
      getWallet: state.getWallet,
      getBalance: state.getBalance,
      getWalletUser: state.getWalletUser,
      sendMoneyRes: state.sendMoney,
      getEarnedIncome: state.getEarnedIncome,
      sendTokenEmail: state.sendTokenEmail
    })
  );

  const formikWallet = useFormik({
    initialValues: {
      amount: ''
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string()
        .required("Please Enter Amount!")
        .matches(/^[0-9]*$/, 'Only number is allowed')
    }),

    onSubmit: values => {

      let userListLength = userList.length;
      const userWalletList = userList;
      let obj = {
        'toAddress': userWalletList, 'amount': values.amount,
        'balance': walletDetails.reWithdrawBalance, 'total_amount': values.amount * userListLength
      }

      if (userList.length > 0 && values.amount) {
        // setSendMoneyLoading(true)
        setTransferDetails(obj)
        setShowSendMoney(false)
        setConfirmPopup(true);
        setSendMoneyLoading(true);
      }
      else {
        setCommonErr({ ...commonErr, 'listErr': 'User and amount required ...' })
        setTimeout(() => {
          setCommonErr('')
        }, 3000);
      }

    }
  });

  const updateBalance = () => {
    setPopupLoading({ ...popupLoading, updateLoading: true })
    dispatch(walletAction.getBalance(apiConstants.UPDATE_BALANCE));
    setTimeout(() => {
      setPopupLoading({ ...popupLoading, updateLoading: true })
      setUpdatePopup(false)
    }, 1200);
  }

  const formikRecover = useFormik({
    initialValues: {
      password: "",
      mnemonic: ""
    },
    validationSchema: Yup.object().shape({
      mnemonic: Yup.string()
        .required("Please Enter mnemonic!!"),
      password: Yup.string()
        .required("Please Enter to password!")
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, 'Minimum eight characters, at least one letter, one number and one special character'),
    }),

    onSubmit: values => {
      if (values.password !== '' && values.mnemonic !== '') {
        setPopupLoading({ ...popupLoading, recoverLoading: true })
        dispatch(walletAction.recoverWallet(apiConstants.RECOVER_WALLET,
          { 'password': values.password, mnemonic: values.mnemonic }));
      }

    }
  });

  const formikWithdraw = useFormik({
    initialValues: {
      send_to: '',
      amount: ''
    },
    validationSchema: Yup.object().shape({
      send_to: Yup.string()
        .required("Please Enter Wallet Addres!"),
      amount: Yup.string()
        .required("Please Enter Amount!")
        .matches(/^[0-9]*$/, 'Only number is allowed')
        .matches(/^.{3,6}$/, 'min and maximum number is 100 and 100000')
    }),

    onSubmit: values => {

      if (values.amount <= 100000) {

        let obj = {
          'toAddress': values.send_to, 'amount': values.amount
        }
        setWithdrawDetails(obj);
        setPopupLoading({ ...popupLoading })
        setTimeout(() => {
          setShowWithdraw(false)
          // setWithdrawPopup(true);
          otpHandler('withdraw')
        }, 2000);
      }
      else {

        setAmtErr("Withdraw limit is 100000");
        setTimeout(() => {
          setAmtErr('')
        }, 3000);
      }
    }
  });

  const handleClose = (name) => {
    setIsCopied(false);

    if (name === "sendMoney") {
      setShowSendMoney(false);
      setUserList([]);
    }

    else if (name === "updatePopup") {
      setShowSendMoney(false);
      setUpdatePopup(false);
    }
    else if (name === "withdrawMoney") {
      setShowWithdraw(false)
    }
    else if (name === "confirmPopup") {
      setConfirmPopup(false)
    }
    else if (name === "withdrawPopup") {
      setWithdrawPopup(false)
    }
  }

  const openPopup = (name) => {
    if (name === 'sendMoney') {
      formikWallet.values.password = '';
      formikWallet.values.amount = '';
      setUserList([]);
      setShowSendMoney(true);
      setSendMoneyLoading(false);
      setPopupLoading({ ...popupLoading, sendLoading: false, otpLoading: false })
    }
    else if (name === 'updatePopup') {
      setUpdatePopup(true);
      setPopupLoading({ ...popupLoading, updateLoading: false })
    }
    else if (name === 'withdrawMoney') {
      formikWithdraw.values.send_to = '';
      formikWithdraw.values.password = '';
      formikWithdraw.values.amount = '';
      setShowWithdraw(true)
      setPopupLoading({ ...popupLoading, withdrawLoading: false, otpLoading: false })
    }
  }

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const deleteRes = (val) => {

    if (val === 'Yes') {
      setShowDelete(false);
    }
    else {
      setShowDelete(false)
    }
  }


  const selectedWalletRes = (val) => {

    if (val === 'Yes') {
      setWalletUpdatePopup(false);
    }
    else if (val === "recover") {
      setWalletUpdatePopup(false)
      setRecoverWalletPopup(true);
    }
    else {
      setWalletUpdatePopup(false)
    }
  }

  const selectedCreateWalletRes = (val) => {
    if (val === 'Yes') {
      setWalletCreatePopup(false);
    }
    else {
      setWalletCreatePopup(false)
    }
  }

  const selectedRecoverWalletRes = (val) => {

    if (val === 'Yes') {
      setRecoverWalletPopup(false);
    }
    else {
      setRecoverWalletPopup(false)
    }
  }

  const fetchWalletDetails = () => {
    dispatch(alertActions.clear());
    dispatch(walletAction.getWallet(apiConstants.GET_WALLET));
    dispatch(genealogyActions.getEarnedIncome(apiConstants.GET_EARNED_INCOME));
  }

  useEffect(() => {
    localStorage.removeItem('withdrawkey')
    localStorage.removeItem('sendToUserkey')
    setUserList([])
    setShowSendMoney(false)
    fetchWalletDetails()
    // dispatch(walletAction.getBalance(apiConstants.GET_BALANCE));
    return () => {
      // apiTimeStopper.cancel();
      setAlertRes('');
      setUserList([]);
      setShowSendMoney(false)
    }
  }, []);

  useEffect(() => {

    if (Object.keys(getEarnedIncome).length > 0) {
      if (getEarnedIncome.isgetEarnedIncome) {
        if (getEarnedIncome.getEarnedIncome) {
          if (getEarnedIncome.getEarnedIncome.data) {
            let lostIncome = 0;
            let lostIncomeData = getEarnedIncome.getEarnedIncome.data.lostIncome;

            lostIncomeData && Object.keys(lostIncomeData).forEach((x) => {
              console.log("lostIncomeData[x]", lostIncomeData[x])
              lostIncome = lostIncome + parseFloat(lostIncomeData[x].toFixed(2))
            })

            setWalletIncome({
              ...walletIncome, 'spentIncome': getEarnedIncome.getEarnedIncome.data.data,
              'earnedIncome': getEarnedIncome.getEarnedIncome.data.earned,
              'lostIncome': lostIncome
            })
          }
        }
      }
    }


  }, [getEarnedIncome]);

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }

    if (Object.keys(getWallet).length > 0 && getWallet.isGetWallet && getWallet.getWallet && getWallet.getWallet.data) {

      setWalletDetails({
        ...walletDetails, 'walletAddress': getWallet.getWallet.data.data.walletAddress,
        repurchaseBalance: getWallet.getWallet.data.data.repurchaseBalance,
        reWithdrawBalance: getWallet.getWallet.data.data.reWithdrawBalance,
        walletBalance: getWallet.getWallet.data.data.balance,
        withdrawBalance: getWallet.getWallet.data.data.withdrawBalance,
        isWalletCreated: getWallet.getWallet.data.data.isWalletCreated,
        isWalletCondition: getWallet.getWallet.data.data.isWalletCondition,
        loginCredit: getWallet.getWallet.data.data.loginCredit,
        textCredit: getWallet.getWallet.data.data.textCredit,
        bannerCredit: getWallet.getWallet.data.data.bannerCredit,
      })

    }

  }, [language, getWallet, getBalance])

  useEffect(() => {
    if (getWalletUser !== undefined) {

      if (Object.keys(getWalletUser).length > 0) {
        if (getWalletUser.isGetWalletUser) {
          if (getWalletUser.getWalletUser) {
            if (flagQuery) {
              let getInitial = getWalletUser.getWalletUser.data.data;
              let arr = [...userList, ...getInitial];
              setUserList(arr)
            }
          }
        }
      }
    }
  }, [getWalletUser])

  useEffect(() => {
    if (Object.keys(sendMoneyRes).length > 0) {
      if (sendMoneyRes.isSendMoney) {
        if (sendMoneyRes.sendMoney) {
          setTransferDetails('')
          setConfirmPopup(false)
          setSendMoneyLoading(false)
          setPopupLoading({ ...popupLoading, sendLoading: false })
          dispatch({ type: 'SEND_MONEY_RESTORE_DEFAULT' });
        }
      }
      else {
        if (!sendMoneyRes.sendMoney) {
          setConfirmPopup(false);
          setSendMoneyLoading(false);
          setPopupLoading({ ...popupLoading, sendLoading: false })
        }
      }
    }
  }, [sendMoneyRes])

  useEffect(() => {
    if (Object.keys(alert).length > 0) {

      let tempErr = [];
      if (alert.type === 'alert-success') {
        tempErr = [{ 'type': alert.type, 'message': alert.message }]
      }
      else if (alert.type === 'alert-danger') {
        if (alert.message.errors !== undefined) {

          if (typeof alert.message.errors == 'object') {

            let keys = Object.keys(alert.message.errors)

            if (keys.length !== 0) {
              keys.map(data => {
                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
              })
            }
          }
          else {
            tempErr = [{ 'type': alert.type, message: alert.message.errors }]
          }
        }
        else {
          tempErr = [{ 'type': alert.type, message: alert.message }]
        }

      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes('')
      }, 3000);
    }


  }, [alert]);

  const handleChange = (e) => {
    setQuery(e.target.value)
  }

  const onHandleClose = (index) => {
    const arr = [...userList];
    arr.splice(index, 1);
    setUserList(arr);
  }

  const onSearch = (e) => {
    e.preventDefault();
    if (query !== '') {
      setQuery('')
      setFlagQuery(true)
      dispatch(walletAction.getWalletUser(apiConstants.GET_WALLET_USER, { searchString: query }));
    }
    else {
      setFlagQuery(false)
      setCommonErr({ ...commonErr, queryErr: 'Please enter value...' })
      setTimeout(() => {
        setCommonErr('')
      }, 3000);
    }
  }

  const apiWorks = () => {

    if (transferDetails !== '') {
      dispatch(walletAction.sendMoney(apiConstants.SEND_USER_MONEY, transferDetails));
      setUserList([]);
      formikWallet.values.amount = ''
    }
    else {
      dispatch(alertActions.error('Already in process...'));
    }
  }

  const apiTimeStopper = function apiTimeStopper(fun, delay) {
    let timer;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fun();
      }, delay);
    }
  }

  const handleConfirm = apiTimeStopper(apiWorks, 2000);
  const handleUpdate = apiTimeStopper(updateBalance, 500)

  const handleWithdraw = () => {
    setPopupLoading({ ...popupLoading, withdrawLoading: true })
    dispatch(walletAction.withdrawMoney(apiConstants.WITHDRAW_MONEY, withdrawDetails));
    handleClose('withdrawPopup')
  }

  const onVerify = () => {
    setOtpShow(false);
    setPopupLoading({ ...popupLoading, otpLoading: true })

    let otpName = otpRequestFrom
    let key = localStorage.getItem(otpName);

    var tokenValidates = speakeasy.totp.verify({
      secret: key,
      encoding: "ascii",
      token: verificationCode.trim(),
      window: 1,
      step: 900
    });
    console.log(tokenValidates)
    if (tokenValidates) {

      if (otpRequestFrom === "withdrawkey") {
        localStorage.removeItem('withdrawkey')
        handleWithdraw();
      }
      else if (otpRequestFrom === "sendToUserkey") {
        localStorage.removeItem('sendToUserkey')
        handleConfirm()
      }
    }
    else {
      setAlertRes([{ 'type': 'alert-error', 'message': 'Code Expires...' }])
    }
  }

  const otpHandler = (name) => {
    if (name === "withdraw") {
      setWithdrawPopup(false);
    } else if (name === "sendToUser") {
      setConfirmPopup(false);
    }
    setOtpShow(true);
    setOtpFlag(true)
    setotpRequestFrom(name + 'key');
    setTimeout(() => {
      dispatch(userActions.sendTokenEmail(apiConstants.WALLET_OTP));
    }, 200)
  }

  useEffect(() => {
    if (Object.keys(sendTokenEmail).length > 0) {

      if (otpFlag) {
        if (sendTokenEmail.isOtpSend)
          if (sendTokenEmail.otpSend.data) {
            localStorage.setItem(otpRequestFrom, sendTokenEmail.otpSend.data.data.key);
          }
      }
    }
  }, [sendTokenEmail])

  return (
    <>

      <div className="main-wrap">
        <Header />
        <div className="dashboard-wraper">
          <Sidebar />
          <div className="content-wrap">
            {alertRes !== '' ?
              alertRes.length > 0 ? alertRes.map((data, index) => {
                return (
                  <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                    className="alertBox">
                    {data.message}
                  </Alert>)
              }) : '' : ''}

            <div className="dashboard-content p">

              <div className="dash-body-blk vh-100-layout">
                <div className="dash-report-blk px-xl-4 px-2 pt-3 vh-100-layout">
                  <div className="row progress-bar-sec">
                    <div className="col-xl-12 col-lg-12 col-md-12 mb-4 inner-progress-bar-sec">
                      <div className="card dash-card tag-block p-3 box_shadow w-100">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <h2 className="heading-top">Wallet Details</h2>
                            <div className="text-right mr-2 div-with-flex-wrap">
                              {walletDetails && !walletDetails.isWalletCreated ?
                                <button className="btn btn-new mb-3" onClick={() => setWalletCreatePopup(true)}>Add Wallet</button> : ''}
                              {walletDetails && !walletDetails.isWalletCondition ?
                                <button className="btn btn-new mb-3" onClick={() => setWalletUpdatePopup(true)}>Update Wallet</button> : ''}
                              {/* Purchase Credit */}
                              <button className="btn btn-new mb-3" onClick={() => setCreditShow(true)}>Purchase Advertisment Credit</button>
                              <button className="btn btn-new mb-3" onClick={() => openPopup('sendMoney')}>Send Money</button>
                              <button className="btn btn-new mb-3" onClick={() => openPopup('withdrawMoney')}>Withdraw Money</button>
                              <button className="btn btn-new mb-3" onClick={() => openPopup('updatePopup')}>{siteConstants.UPDATE_BALANCE}</button>
                            </div>
                          </div>
                        </div>
                        <div className="card-ttl">

                          <div className="row div-center">

                            {/* <div className='col-lg-12 div-center'> */}
                            {/* Wallet Balance */}
                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <div className='div-center-align wallet-box shadow-wallet'>
                                <img src="assets/images/tron-coin.png" style={{ 'width': '45px', 'height': '45px' }} alt="tron-coin" />
                                <div className="mt-3 text-center">
                                  <h2 className="mb-3">Balance </h2>
                                  <p className='m-1 d-block f-13'> Re-Purchase Balance {walletDetails.repurchaseBalance ? " " + parseFloat(walletDetails.repurchaseBalance).toFixed(2) : 0} </p>
                                  <p className='m-1 d-block f-13'>Withdraw Balance  {walletDetails.reWithdrawBalance ? " " + parseFloat(walletDetails.reWithdrawBalance).toFixed(2) : 0} </p>
                                  {/* <h4 className="p-2">{walletDetails !== undefined ? parseFloat(walletDetails.repurchaseBalance).toFixed(2) : ''}</h4> */}
                                  {walletDetails.withdrawBalance && walletDetails.withdrawBalance !== '0' ?
                                    <h6 className='text-secondary'>Withdrawal Requested for <span className='text-danger'>{walletDetails.withdrawBalance}</span> </h6>
                                    : ''}
                                </div>
                              </div>
                            </div>

                            {/* Advertisment Balance */}
                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <div className='div-center-align wallet-box shadow-wallet'>
                                <img src="assets/images/tron-coin.png" style={{ 'width': '45px', 'height': '45px' }} alt="tron-coin" />
                                <div className="mt-3 text-center">
                                  <h2 className="mb-3">Advertisment Credit</h2>
                                  {walletDetails.loginCredit ? <p className='m-1 d-block f-13'>Login Credit  {" " + parseFloat(walletDetails.loginCredit).toFixed(2)} </p> : ''}
                                  {walletDetails.textCredit ? <p className='m-1 d-block f-13'> Text Credit  {" " + parseFloat(walletDetails.textCredit).toFixed(2)} </p> : ''}
                                  {walletDetails.bannerCredit ? <p className='m-1 d-block f-13'> Banner Credit {" " + parseFloat(walletDetails.bannerCredit).toFixed(2)} </p> : ''}

                                </div>
                              </div>
                            </div>

                            {/* Donation recieved  */}
                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <div className='div-center-align wallet-box shadow-wallet'>
                                <img src="assets/images/tron-coin.png" style={{ 'width': '45px', 'height': '45px' }} alt="tron-coin" />
                                <div className="mt-3 text-center">
                                  <h2 className="mb-3">Total Donations Received</h2>
                                  <h4 className="p-2">{(parseFloat(walletIncome.earnedIncome) - parseFloat(walletIncome.lostIncome)).toFixed(2)}</h4>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row my-3 mx-1 p-3 shadow-wallet">
                            <div className="col-lg-3 col-md-3">
                              {
                                walletDetails.walletAddress !== undefined &&
                                  walletDetails.walletAddress !== null && walletDetails.walletAddress !== '' ?
                                  <QRCode
                                    value={walletDetails.walletAddress}
                                    size={128}
                                  />
                                  : ''
                              }

                            </div>
                            <div className="col-lg-9 col-md-9">
                              <p>Use the following data to credit your account</p>
                              <div className="form-group modal-input">
                                <label>Your Wallet Address</label>

                                <div className="d-flex justify-content-center align-items-center">
                                  <input type="text" className="form-control" name="walletAddress"
                                    value={walletDetails.walletAddress !== undefined ? walletDetails.walletAddress : ''} readOnly={true} />
                                  <CopyToClipboard text={walletDetails.walletAddress} onCopy={onCopyText}>
                                    <div className="copy-area">
                                      <button className="btn btn-new mr-3">Copy Address</button>
                                    </div>
                                  </CopyToClipboard>
                                  {isCopied ?
                                    <span className={isCopied ? "text-primary" : ""}>
                                      Copied!
                                    </span> : ''}
                                </div>

                              </div>
                            </div>
                          </div>
                          {/* </div> */}

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              {/* Banner Scetion */}
              <div className='col-md-12 mt-3'>
                <BannerRandomFooter />
              </div>
            </div>
            <Footer />
          </div>

        </div>
      </div >

      {/* send money to other users */}
      <Modal show={showSendMoney} onHide={() => handleClose('sendMoney')} size="xxl" animation={true}>
        <Modal.Header closeButton>
          <div>
            <Modal.Title>{siteConstants.SEND_MONEY}</Modal.Title>
          </div>
        </Modal.Header>

        <form onSubmit={formikWallet.handleSubmit}>
          <Modal.Body>

            <div className="form-group modal-input">

              <label>User Name</label>
              <input type="text" name="query" placeholder="search by username" className="form-control" value={query}
                onChange={(e) => handleChange(e)}></input>
              {commonErr.queryErr !== undefined && commonErr.queryErr !== '' ? <label className="text-danger mt-1 mb-1">{commonErr.queryErr}</label> : ''}
              {commonErr.listErr !== undefined && commonErr.listErr !== '' ? <label className="text-danger mt-1 mb-1">{commonErr.listErr}</label> : ''}

              <button className="btn btn-primary mt-3" onClick={(e) => onSearch(e)}>Search</button>
            </div>

            {userList !== undefined && userList !== null ?
              userList.length > 0 ?
                <div className="suggestion-box shadow-wallet mt-2 mb-2 p-3 d-flex flex-wrap">
                  {
                    userList.map((data, index) => {
                      return (
                        <>
                          <h6 key={index} className="text-secondary p-2 f-13"><span>{data.email}
                            <i className="far fa-times-circle float-right ml-2" onClick={() => onHandleClose(index)}></i></span>  </h6>
                        </>
                      )
                    })
                  }
                </div>
                : '' : ''}

            <div className="form-group modal-input">
              <label>Amount</label>
              <input type="text" className="form-control" name="amount"
                value={formikWallet.values.amount} onChange={formikWallet.handleChange} onBlur={formikWallet.handleBlur} />
              <label className="text-danger">
                {formikWallet.errors.amount && formikWallet.touched.amount ?
                  formikWallet.errors.amount : ''
                }
              </label>
            </div>

          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary btn-gray" onClick={() => handleClose('sendMoney')}>{siteConstants.LABEL_CLOSE}</Button>
            {!sendMoneyLoading ?
              <Button variant="primary" type="submit" disabled={sendMoneyLoading}>{siteConstants.BTN_SEND}</Button>
              :
              <button type="button" className="btn btn-default" disabled={sendMoneyLoading}>
                <i className="fas fa-spinner fa-spin"></i>
              </button>
            }
          </Modal.Footer>
        </form>
      </Modal>

      {/* update confirmation popup */}
      <Modal show={updatePopup} onHide={() => handleClose('updatePopup')} size="xxl" animation={true}>
        <Modal.Header closeButton>
          <div>
            <Modal.Title>{siteConstants.UPDATE_BALANCE}</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <h6 className='text-primary'>Are you sure ,you want to update the balance ?</h6>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary btn-gray" onClick={() => handleClose('updatePopup')}>{siteConstants.LABEL_CLOSE}</Button>
          {/* <Button variant="primary" type="submit">{siteConstants.BTN_SAVE}</Button> */}
          {!popupLoading.updateLoading ?
            <Button variant="primary" type="button" onClick={() => handleUpdate()} disabled={popupLoading.updateLoading}>{siteConstants.BTN_UPDATE}</Button>
            :
            <button type="button" className="btn btn-default" disabled={popupLoading.updateLoading}>
              <i className="fas fa-spinner fa-spin"></i>
            </button>
          }
        </Modal.Footer>

      </Modal>

      {/* withdrawal confirmation popup */}
      <Modal show={confirmPopup} onHide={() => handleClose('confirmPopup')} size="xxl" animation={true}>
        <Modal.Header>
          <div>
            <Modal.Title>{siteConstants.CONFIRM_TRANSFER}</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="user-list">
            <ul>
              {transferDetails.toAddress !== undefined && transferDetails.toAddress !== null ?
                transferDetails.toAddress.length > 0 ?
                  transferDetails.toAddress.map((data, index) => {
                    let firstname = data.firstname !== undefined && data.firstname !== null ? data.firstname : '';
                    let lastname = data.lastname !== undefined && data.lastname !== null ? data.lastname : '';
                    let name = firstname + " " + lastname
                    return (
                      <li key={index} className="text-secondary text-decoration-none f-14">
                        <p className="m-1">Name : {name} </p>
                        <p className="m-1">Wallet Address : {data.walletDetails.wallet_address.base58}</p>
                        <p className="m-1">Email : {data.email}</p>
                      </li>
                    )
                  })
                  : '' : ''}
            </ul>
          </div>
        </Modal.Body>

        <Modal.Footer>

          <Button variant="secondary btn-gray" onClick={() => handleClose('confirmPopup')}>{siteConstants.LABEL_CANCEL}</Button>
          {/* <Button variant="primary" type="button" onClick={() => handleConfirm()}>{siteConstants.BTN_CONFIRM}</Button> */}
          {!popupLoading.sendLoading ?
            <Button variant="primary" type="button" disabled={popupLoading.sendLoading} onClick={() => otpHandler('sendToUser')} > {siteConstants.BTN_CONFIRM}</Button>
            :
            <button type="button" className="btn btn-new blue-layout" disabled={popupLoading.sendLoading}>
              <i className="fas fa-spinner fa-spin"></i></button>
          }
        </Modal.Footer>

      </Modal>

      {/* withdraw for popup */}
      <Modal show={showWithdraw} onHide={() => handleClose('withdrawMoney')} size="xxl" animation={true}>
        <Modal.Header closeButton>
          <div>
            <Modal.Title>{siteConstants.WITHDRAW_MONEY}</Modal.Title>
          </div>
        </Modal.Header>

        <form onSubmit={formikWithdraw.handleSubmit}>
          <Modal.Body>

            <div className="form-group modal-input">
              <label>Sending To (Wallet Address)</label>
              <input type="text" className="form-control" name="send_to"
                value={formikWithdraw.values.send_to} onChange={formikWithdraw.handleChange} onBlur={formikWithdraw.handleBlur} />
              <label className="text-danger">
                {formikWithdraw.errors.send_to && formikWithdraw.touched.send_to ?
                  formikWithdraw.errors.send_to : ''
                }
              </label>
            </div>

            <div className="form-group modal-input">
              <label>Amount</label>
              <input type="text" className="form-control" name="amount"
                value={formikWithdraw.values.amount} onChange={formikWithdraw.handleChange} onBlur={formikWithdraw.handleBlur} />
              <label className="text-danger">
                {formikWithdraw.errors.amount && formikWithdraw.touched.amount ?
                  formikWithdraw.errors.amount : ''
                }
                {amtErr !== undefined && amtErr !== '' ?
                  amtErr : ''
                }
              </label>
            </div>

          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary btn-gray" onClick={() => handleClose('withdrawMoney')}>{siteConstants.LABEL_CLOSE}</Button>
            {!withdrawLoading ?
              <Button variant="primary" type="submit" disabled={withdrawLoading}>{siteConstants.BTN_SEND}</Button>
              :
              <button type="button" className="btn btn-default" disabled={withdrawLoading}>
                <i className="fas fa-spinner fa-spin"></i>
              </button>
            }
          </Modal.Footer>
        </form>
      </Modal>

      {/* withdraw confirmation popup */}
      <Modal show={withdrawPopup} onHide={() => handleClose('withdrawPopup')} size="xxxl" animation={true}>
        <Modal.Header>
          <div>
            <Modal.Title>{siteConstants.CONFIRM_TRANSFER}</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <h5>{siteConstants.ARE_YOU_SURE_YOU_WANT_TO_WITHDRAW} ?</h5>
          {withdrawDetails.amount && parseInt(withdrawDetails.amount) >= 1000 ? <small className='text-info'>This withdrawal is applicable for automatic position purchase for you so 30% of withdrawal
            money is used to purchase position in DJ7 Lite</small> : ''}
          <div className="user-list">
            <p className="m-1"><b className='text-dark'>Transfer in Wallet Address</b> : {withdrawDetails.toAddress}</p>
            <p className="m-1"><b className='text-dark'>Transfer Amount</b> : {withdrawDetails.amount}</p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary btn-gray" onClick={() => handleClose('withdrawPopup')}>{siteConstants.LABEL_CANCEL}</Button>
          {!popupLoading.withdrawLoading ?
            <Button variant="primary" type="button" disabled={popupLoading.withdrawLoading} onClick={() => otpHandler('withdraw')}>{siteConstants.BTN_CONFIRM}</Button>
            :
            <button type="button" className="btn btn-default" disabled={popupLoading.withdrawLoading}>
              <i className="fas fa-spinner fa-spin"></i>
            </button>
          }
        </Modal.Footer>

      </Modal>

      {/* otp for send and withdraw popup */}
      <Modal show={otpShow} onHide={() => setOtpShow(false)} size="xxl" animation={false}>
        <Modal.Header closeButton>
          <div>
            <Modal.Title>{siteConstants.OTP_CODE}</Modal.Title>
            <small className="font-gray mt-2">We have sent an OTP to your registered email address. It will expire in 5 minutes.</small>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="modal-input form-group">
            <input type="text" className="form-control" id="verificationCode" onChange={(e) => setVerificationCode(e.target.value)}
              placeholder={siteConstants.ENTER_VERIFICATION_CODE + ":"} name="verificationCode" />
          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary btn-gray" onClick={() => setOtpShow(false)}>{siteConstants.LABEL_CLOSE}</Button>
          {!popupLoading.otpLoading ?
            <Button variant="primary" onClick={() => onVerify()}>{siteConstants.BTN_SAVE}</Button>
            :
            <button type="button" className="btn btn-default" disabled={popupLoading.otpLoading}>
              <i className="fas fa-spinner fa-spin"></i>
            </button>
          }
        </Modal.Footer>
      </Modal>

      {showDelete ? <DeletePopup onSelect={deleteRes} /> : ''}

      {walletUpdatePopup ? <WalletUpdatePopup onSelect={selectedWalletRes} /> : ''}

      {walletCreatePopup ? <CreateWalletPopup onSelect={selectedCreateWalletRes} /> : ''}

      {recoverWalletPopup ? <RecoverWalletPopup onSelect={selectedRecoverWalletRes} /> : ''}

      {creditShow ? <CreditPurchasePopup onClose={() => { setCreditShow(false); fetchWalletDetails() }} show={creditShow} /> : ''}

    </>
  );
}

export default Wallet