import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
export default function ConfirmPopup(props) {

    const [siteConstants, setSiteConstants] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (props.show) {
            setIsLoading(false)
        }
    }, [props.show])

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language])

    const handleSubmit = (val) => {
        if (val === "Yes") {
            setIsLoading(true)
        }
        props.eventHandler({ response: val, data: props.details })
    }

    return (
        <Modal show={props.show} size="xxxxl" className="delete-popup" animation={true}>
            <Modal.Header closeButton>
                <div>
                    <Modal.Title>
                        <h6>{props && props.header ? props.header : ''}</h6>
                    </Modal.Title>
                </div>
            </Modal.Header>

            {props.showDescription ?
                <Modal.Body>
                    {props.fromComponent === "globalPool" ?
                        <>
                            <p className='ml-1 mb-2'>
                                {props && props.planDetails ?
                                    `You need to purchase minimum of ${props.planDetails.minimumPosition} positions ,each position cost ${props.planDetails.description}`
                                    : ''
                                }
                            </p>

                            <p className='m-1'>You will become sponsor of this user </p>

                            {props && props.details ?
                                <>
                                    <p className='m-1'><b>Username </b> : {props.details.username}</p>
                                    <p className='m-1'><b>Name </b> :  {props.details.firstname ? props.details.firstname : '' + props.details.lastname ? props.details.lastname : ''}</p>
                                </>
                                : ''}
                        </>

                        : ''}
                    {/* html content will display here directly */}
                    {props.htmlContent ? props.htmlContent : ''}

                </Modal.Body>
                : ''}

            <Modal.Footer>
                <Button variant="secondary btn-gray notify-cursor" onClick={() => handleSubmit('No')}>{siteConstants.LABEL_NO}</Button>
                {isLoading ?

                    <Button variant="secondary" disabled="true" type="button"><i className="fas fa-spinner fa-spin"></i></Button>
                    :
                    <Button variant="primary" onClick={() => handleSubmit('Yes')} type="button">{siteConstants.LABEL_YES}</Button>
                }



            </Modal.Footer>

        </Modal>
    )
}
