import React, { useState } from 'react'
import './TreeStructure.css';
import Tree from 'react-d3-tree';
import { useEffect } from 'react';
import { useCenteredTree } from "./treeHelpers";


const TreeStructure = ({ matrixPosition, previousBoard, levelNumber, translateX, translateY, isDashboard }, props) => {
    const [childNodesArr, setChildNodesArr] = useState([])
    const [selectedNode, setSelectedNode] = useState()
    const [treeChart, setTreeChart] = useState()
    const [dimensions, translate, containerRef] = useCenteredTree();


    const renderRectSvgNode = ((nodeDatum, handleNodeClick, foreignObjectProps) => {
        let isDownline = nodeDatum.hasOwnProperty('children');
        return (
            <>
                <foreignObject display="block" onClick={() => handleNodeClick(nodeDatum)} x="-33" y="-0" width="80" height="80" {...foreignObjectProps}>
                    <img src='../assets/images/matrixProfile.png' alt="userLogo" height="200" width="200" />
                    {/* className={'treeUser'+levelNumber} */}
                </foreignObject>
                <text className="textNode" fill="black" strokeWidth="1" x="-35" y="0" dy={isDownline ? "-3" : "20"}>
                    {nodeDatum.name}
                </text>
            </>
        )
    }
    );

    const handleNodeClick = (nodeDatum) => {
        let index = childNodesArr.findIndex((x) => nodeDatum.name === x.positionNumber)
        if (index > -1) {
            setSelectedNode(childNodesArr[index])
        }
    };

    useEffect(() => {
        if (matrixPosition && Object.keys(matrixPosition).length > 0) {
            let arrLength = matrixPosition.length;
            let tempChildArr = [];
            if (arrLength > 1) {
                setChildNodesArr(matrixPosition)
            }
            arrLength > 1 && matrixPosition.slice(1, arrLength).map((x) => {
                return tempChildArr.push({ name: x.positionNumber })
            })
            
            setTreeChart({ name: matrixPosition[0].positionNumber, children: tempChildArr.length > 0 ? tempChildArr : [] })
            
        }
        
    }, [matrixPosition])

    return (
        <>
            {treeChart ?
                <div ref={containerRef} className={'shadow-sm p-3 mb-5 bg-white rounded div-center ' + isDashboard ? 'h-100per' : 'h-100per'} id="treeWrapper"
                    style={{ width: props.width, height: props.height }}>
                    <Tree
                        draggable={false}
                        zoomable={false}
                        // zoom={0}
                        scaleExtent={{ min: 0.9 }}
                        dimensions={dimensions}
                        // translate={translate}
                        translate={{ x: translateX, y: translateY }}
                        data={treeChart}
                        nodeSvgShape={{ "shape": 'none' }}
                        pathFunc="step"
                        orientation="vertical"
                        allowForeignObjects={true}
                        initialDepth={0.02}
                        rootNodeClassName="node__root"
                        branchNodeClassName="node__branch"
                        leafNodeClassName="node__leaf"
                        renderCustomNodeElement={(props) => renderRectSvgNode(props.nodeDatum, handleNodeClick, props.foreignObjectProps)}
                    />
                </div>
                : ''}

            {selectedNode ?
                <ul className="tree-card card-wrapper position-absolute" style={{bottom: '5%'}}>
                    <li class="card">
                        <p className='text-right close-icon m-0' onClick={() => setSelectedNode()}>&times;</p>
                        <div className="d-flex justify-content-center text-center flex-column h-100">
                            <h6>{selectedNode.positionNumber}</h6>
                            <p>{selectedNode.userDetails[0].username}</p>
                        </div>
                    </li>
                </ul>
                : ''}
        </>

    )

}

export default TreeStructure;