import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, userActions, genealogyActions, globalPoolUserActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../Common/Pagination/Pagination';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import ConfirmPopup from '../Common/Popup/ConfirmPopup';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';

var momentTimeZone = require('moment-timezone');
const timeZone = momentTimeZone.tz.setDefault("America/New_York");

const UserListing = () => {

    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);
    const [userList, setUserList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalpages] = useState(1);
    const [show, setShow] = useState(false);
    const [isShow, setIsShow] = useState(false)
    const [tempActiveuser, setTempActiveuser] = useState('')
    const [availablePlan, setAvailablePlan] = useState('')
    const [query, setQuery] = useState('');
    const [tempUserId, setTempUserId] = useState();

    const { alert, language, getNewMember, getAvailablePlan } = useSelector(
        state => ({
            alert: state.alert,
            language: state.language,
            getNewMember: state.getNewMember,
            getAvailablePlan: state.getAvailablePlan
        })
    );
    const handleClose = () => setShow(false);

    const openPopup = (email) => {
        setShow(true)
        formik.values.email_message = '';
        formik.values.email_subject = ''
        // setEmailData({ ...emailData, 'email_to': email })
        formik.values.email_to = email
    }

    const apiTimeStopper = function apiTimeStopper(fun, delay) {
        let timer;
        return function () {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fun();
            }, delay);
        }
    }

    const eventRes = (data) => {
        if (data.response === "Yes") {
            apiTimeStopper(forReferalPurchase(data.data), 2000);
            setTimeout(() => {
                setIsShow(false)
            }, 1000);
        } else {
            setIsShow(false)
        }
    }

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
        if (getNewMember !== undefined && getNewMember !== null) {
            if (Object.keys(getNewMember).length > 0) {
                if (getNewMember.getNewMember !== undefined) {
                    if (getNewMember.getNewMember.data !== undefined) {

                        const { totalPages, items } = getNewMember.getNewMember.data.data;
                        if (items.length > 0) {
                            setUserList(items);
                            setTotalpages(totalPages);
                        }
                    }
                }
            }
        }
    }, [language, getNewMember]);

    useEffect(() => {
        if (getAvailablePlan && Object.keys(getAvailablePlan).length > 0) {
            if (getAvailablePlan.isGetAvailablePlan) {
                setAvailablePlan(getAvailablePlan.getAvailablePlan.data.data)
            }
        }
    }, [getAvailablePlan])

    const formik = useFormik({
        initialValues: {
            email_to: "",
            email_subject: "",
            email_message: "",
        },
        validationSchema: Yup.object().shape({
            email_to: Yup.string()
                .required("Please Enter to email!"),
            email_subject: Yup.string()
                .required("Please Enter email subject!"),
            email_message: Yup.string()
                .required("Please Enter email message")
        }),

        onSubmit: values => {

            let obj = {
                send_to: 'User',
                notification_for: values.email_to !== undefined ? values.email_to : '',
                notification_message: values.email_message,
                notification_subject: values.email_subject
            }
            dispatch(userActions.personalisedEmail(apiConstants.SEND_EMAIL_NOTIFICATIONS, obj));
            setTimeout(() => {
                setShow(false)
            }, 2000);
            // dispatch(userActions.sendEmail(apiConstants.VERIFY_EMAIL, obj));
        }
    });

    useEffect(() => {
        let obj = {
            page: 1,
            perPage: 10,
            orderByAscDesc: -1,
            limitQuery: false
        };
        dispatch(alertActions.clear());
        dispatch(genealogyActions.getNewMember(apiConstants.GET_NEW_MEMBERS, obj));
        dispatch(globalPoolUserActions.getAvailablePlan(apiConstants.GET_AVAILABLE_PLAN));
        return () => {
            setAlertRes('')
        }
    }, []);

    useEffect(() => {
        if (Object.keys(alert).length > 0) {
            let tempErr = [];
            if (alert.type === 'alert-success') {
                tempErr = [{ 'type': alert.type, 'message': alert.message }]
            }
            else if (alert.type === 'alert-danger') {
                if (alert.message.errors !== undefined) {
                    let keys = Object.keys(alert.message.errors)
                    if (keys.length !== 0) {
                        keys.map(data => {
                            return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
                        })
                    }
                }
                else {
                    tempErr = [{ 'type': alert.type, message: alert.message }]
                }

            }

            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes('')
            }, 8000);
        }
    }, [alert]);

    const makeHttpRequestWithPage = (pageNumber) => {
        setCurrentPage(pageNumber);
        let obj = {
            page: pageNumber,
            perPage: 10,
            orderByAscDesc: -1,
            limitQuery: false
        };
        if (query) {
            obj.query = query
        }
        dispatch(genealogyActions.getNewMember(apiConstants.GET_NEW_MEMBERS, obj));
    }

    const forReferalPurchase = (userDetail) => {
        dispatch(globalPoolUserActions.forReferalPurchase(apiConstants.PURCHASE_FOR_REFERAL_USER, { referalUser: { ...userDetail, _id: tempUserId } }));
    }

    const searchUser = (e) => {
        if (e.key === 'Enter') {
            let obj = { page: 1, perPage: 10, limitQuery: false };
            obj.query = e.target.value;
            setQuery(obj.query);
            dispatch(genealogyActions.getNewMember(apiConstants.GET_NEW_MEMBERS, obj));
        }
    }

    let totalPage = typeof userList !== 'undefined' && Object.keys(userList).length > 0 ? totalPages : 0;

    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== undefined && alertRes !== null && alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {

                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                                        className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}

                        <div className="dashboard-content p">
                            <div className="dash-body-blk">
                                <div className="dash-report-blk px-xl-4 px-2 pt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row progress-bar-sec">
                                                <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                                                    <div className="card dash-card tag-block p-3 box_shadow">
                                                        <div className="row">
                                                            <div className="col-lg-9">  <h2 className="heading-top">User Listing</h2>  </div>
                                                        </div>

                                                        <div className="row m-2">
                                                            <div className="col-sm-12 col-md-12 d-flex justify-content-end">
                                                                <div id="dataTable_filter" className="dataTables_filter">
                                                                    <label>
                                                                        <input type="search" placeholder="search"
                                                                            className="form-control form-control-sm"
                                                                            aria-controls="dataTable"
                                                                            onKeyDown={(e) => searchUser(e)} />
                                                                        <small className='text-danger f-10'>Press Enter for search ...</small>

                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="owned-sec scroll-layout">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Joined Date</th>
                                                                        <th>Email</th>
                                                                        <th>Username </th>
                                                                        <th>Contact</th>
                                                                        <th>Status</th>
                                                                        {/* <th>Position Count</th> */}
                                                                        <th className='text-center'>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {userList !== undefined ?
                                                                        userList.length > 0 ? userList.map((data, index) => {
                                                                            let user = data.items[0].userDetail;
                                                                            let userId = data.items[0].userId;

                                                                            let firstname = user.firstname !== undefined && user.firstname !== null ? user.firstname : ''
                                                                            let lastname = user.lastname !== undefined && user.lastname !== null ? user.lastname : ''
                                                                            let name = firstname + " " + lastname;
                                                                            let d = new Date(user.created_date);

                                                                            let arr = '';
                                                                            let contactData = '';
                                                                            let country_code = '';
                                                                            if (user.contact !== undefined && user.contact !== null) {
                                                                                arr = user.contact.split(" ");
                                                                                contactData = arr[arr.length - 1]
                                                                                arr.pop();
                                                                                country_code = arr.join(' ')
                                                                            }

                                                                            let count = data.items[0].matrixDetails.length ? 1 : 0 + data.items[0].planDetails.length ? 1 : 0 +
                                                                                data.items[0].positionDetails.length ? 1 : 0

                                                                            let status = 'pending';
                                                                            if (count) {
                                                                                if (count > 0) {
                                                                                    status = 'approved'
                                                                                }
                                                                            }

                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{name}</td>
                                                                                    {/* <td>{d.toLocaleString("en-US", options)}</td> */}
                                                                                    <td>{momentTimeZone.tz.guess() ?
                                                                                        momentTimeZone(d).tz(momentTimeZone.tz.guess()).format('DD MMMM YYYY')
                                                                                        :
                                                                                        momentTimeZone(d).tz(timeZone).format('DD MMMM YYYY')}</td>
                                                                                    <td>{user.email}</td>
                                                                                    <td>{user.username}</td>
                                                                                    <td>{contactData !== '' ? (country_code !== '' ? country_code + " " : '') + contactData : ''}</td>
                                                                                    <td className={status === 'approved' ? 'text-success' : 'text-danger'}>{status}</td>
                                                                                    {/* <td>{amountSpent}</td> */}
                                                                                    <td className="d-flex flex-column justify-content-center text-center">
                                                                                        {user.pool_status === "await" ?
                                                                                            // <a className="mailIcon" title="View" onClick={() => { setIsShow(true); setTempActiveuser(user) }} data-toggle="modal" data-target="#emailModal">
                                                                                            // <button className='btn btn-primary f-14' onClick={() => forReferalPurchase(user)}>Purchase Position</button>
                                                                                            <p className='notify-cursor p-2 border border-primary f-12 rounded text-secondary mb-2' onClick={() => { setIsShow(true); setTempActiveuser(user); setTempUserId(userId) }}>Purchase Position</p>

                                                                                            : ''}

                                                                                        <p className="mailIcon text-primary notify-cursor" title="View" onClick={() => openPopup(user.email)} data-toggle="modal" data-target="#emailModal">
                                                                                            <span><img src="assets/images/mail-icon.png" alt="View" /></span></p>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                            : <tr><td className="mt-2" colSpan="8">No Record Found </td></tr> : ''}

                                                                </tbody>
                                                            </table>
                                                            {typeof userList !== 'undefined' ?
                                                                <Pagination totalPage={totalPage} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                                                                : ''}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Banner Scetion */}
                            <div className='col-md-12 mt-3'>
                                <BannerRandomFooter />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>

            </div >

            {/* Referal User Popup */}
            <ConfirmPopup
                show={isShow}
                header="Are You sure you want purchase position for this user ?"
                details={tempActiveuser}
                planDetails={availablePlan}
                eventHandler={(e) => eventRes(e)}
                showDescription={true}
                htmlContent={<><p> UserName : {tempActiveuser.username}</p> <p>Email : {tempActiveuser.email}</p></>}
                fromComponent="userListing"
            />



            <Modal show={show} onHide={() => handleClose()} size="xxl" animation={true}>
                <Modal.Header closeButton>
                    <div>
                        <Modal.Title>{siteConstants.PERSONALISED_MESSAGE}</Modal.Title>
                    </div>
                </Modal.Header>

                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>

                        <div className="form-group">
                            <label>Email to</label>
                            <input type="text" className="form-control" name="email_to" readOnly={true}
                                value={formik.values.email_to} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            <label className="text-danger">
                                {formik.errors.email_to && formik.touched.email_to ?
                                    formik.errors.email_to : ''
                                }
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Subject</label>
                            <input type="text" className="form-control" name="email_subject"
                                value={formik.values.email_subject} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            <label className="text-danger">
                                {formik.errors.email_subject && formik.touched.email_subject ?
                                    formik.errors.email_subject : ''
                                }
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Message</label>
                            <textarea className="form-control" name="email_message"
                                value={formik.values.email_message} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            <label className="text-danger">
                                {formik.errors.email_message && formik.touched.email_message ?
                                    formik.errors.email_message : ''
                                }
                            </label>
                        </div>
                        {/* <button type="submit" className="btn btn-primary">Submit</button> */}


                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary btn-gray" onClick={() => handleClose()}>{siteConstants.LABEL_CLOSE}</Button>
                        <Button variant="primary" type="submit">{siteConstants.BTN_SAVE}</Button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default UserListing;
