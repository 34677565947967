import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { advertismentAction } from '../../../actions';
import { apiConstants, bannerTypeConstants } from '../../../constants';
const publicIp = require("public-ip");

export default function Footer() {
    const dispatch = useDispatch();
    const [textBanner, setTextBanner] = useState([])
    const [imgBanner, setImgBanner] = useState([])

    const { FOOTER_TEXT_ADS_LIMIT, FOOTER_BANNER_ADS_LIMIT, BANNER_ADS,
        TEXT_TEMPLATE1, TEXT_TEMPLATE2, BANNER_FIXED_HEIGHT, BANNER_FIXED_WIDTH } = bannerTypeConstants;

    const { getFooterRandomBanner, getBannerFooterRandomBanner } = useSelector(state => ({
        getFooterRandomBanner: state.getFooterRandomBanner,
        getBannerFooterRandomBanner: state.getBannerFooterRandomBanner
    }));

    const fetchBanners = async () => {
        let ipAddress = await publicIp.v4();
        dispatch(advertismentAction.getBannerFooterRandomBanner(apiConstants.GET_FOOTER_BANNER,
            {
                type: BANNER_ADS,
                perPage: FOOTER_TEXT_ADS_LIMIT,
                ipAddress: ipAddress
            }));
        dispatch(advertismentAction.getFooterRandomBanner(apiConstants.GET_FOOTER_BANNER,
            {
                perPage: FOOTER_TEXT_ADS_LIMIT,
                ipAddress: ipAddress
            }));
    }

    useEffect(() => {
        fetchBanners()
    }, []);

    useEffect(() => {
        if (getFooterRandomBanner) {
            if (Object.keys(getFooterRandomBanner).length > 0) {
                if (getFooterRandomBanner.getFooterRandomBanner) {
                    if (getFooterRandomBanner.getFooterRandomBanner.data) {
                        const { data } = getFooterRandomBanner.getFooterRandomBanner.data.data;
                        if (data.length > 0) {
                            setTextBanner(data);
                        }
                    }
                }
            }
        }

        if (getBannerFooterRandomBanner && Object.keys(getBannerFooterRandomBanner).length > 0) {
            if (getBannerFooterRandomBanner.getBannerFooterRandomBanner) {
                if (getBannerFooterRandomBanner.getBannerFooterRandomBanner.data) {
                    const { data } = getBannerFooterRandomBanner.getBannerFooterRandomBanner.data.data;
                    if (data.length > 0) {
                        setImgBanner(data);
                    }
                }
            }
        }

    }, [getFooterRandomBanner, getBannerFooterRandomBanner])

    const bannerAds = (data, index) => {
        return (
            <>
                <div className='col-md-5 p-1 text-center d-flex items-center' key={index} style={{ height: BANNER_FIXED_HEIGHT + 'px', width: BANNER_FIXED_WIDTH + 'px' }}>
                    <a href={data.link} target='blank'>
                        <img className="ad-banner" style={{ height: BANNER_FIXED_HEIGHT - 15 + 'px', width: BANNER_FIXED_WIDTH + 'px' }} src={data.files} alt="logo" /></a>
                </div>
                <div className='col-md-1'></div>
            </>
        )
    }

    return (
        <>
            {textBanner && textBanner.length > 0 ?
                <section style={{backgroundColor: '#cbe8f5'}} className='card dash-card tag-block p-3 box_shadow mt-2 mb-2'>
                    <h2 className="heading-top">Text Ads</h2>
                    <div className='d-flex flex-wrap'>
                        {textBanner.map((data, index) => {
                            return (
                                <div className={`text-ellipsis card mb-3 m-1  ${data.type === TEXT_TEMPLATE1 ? 'text-secondary bg-light' : 'text-white bg-info'}`}
                                    style={{ 'height': BANNER_FIXED_HEIGHT + 'px', width: BANNER_FIXED_WIDTH + 'px' }}>
                                    <div className={`text-uppercase p-2 text-primary fw-600 ${data.type === TEXT_TEMPLATE1 ? 'border-bottom border-secondary' : 'border-light-secondary'} text-center`}>{data.bannerTitle ? data.bannerTitle : 'Header'}</div>
                                    <div className="template-card-layout text-center register-banner-layout m-2 d-flex justify-content-center flex-column">

                                        <small className="card-text">
                                            {data.bannerDescription?.bannerdescriptionLine1 ? data.bannerDescription.bannerdescriptionLine1 :
                                                'Some quick example'}</small>
                                        <small className="card-text">
                                            {data.bannerDescription?.bannerdescriptionLine2 ? data.bannerDescription.bannerdescriptionLine2 :
                                                'Some quick example text '}</small>
                                        <small className="card-text">
                                            {data.bannerDescription?.bannerdescriptionLine3 ? data.bannerDescription.bannerdescriptionLine3 :
                                                'Some example'}</small>
                                    </div>
                                    <a key={index} className={`text-center g-13 mx-1 ${data.type === TEXT_TEMPLATE1 ? 'text-secondary' : 'text-white'}`} style={{ textDecoration: 'none' }} data-title={data.link} href={data.link} target="blank">
                                        <small className='text-primary fw-600'>Click Here Now </small>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </section>
                : ''}

            {imgBanner && imgBanner.length > 0 ?
                <section style={{backgroundColor: '#cbe8f5'}}  className='card dash-card tag-block p-3 box_shadow mt-2 mb-2'>
                    <h2 className='heading-top'>Banner Ads</h2>
                    <div className='row'>
                        {
                            imgBanner.map((data, index) => {
                                return bannerAds(data, index)
                            })
                        }
                    </div>
                </section> : ''}
        </>

    )
}
